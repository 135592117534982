@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@font-face {
  font-family: "Inter var";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("/assets/fonts/Inter-roman-var.woff2?v=3.19") format("woff2");
  font-named-instance: "Regular";
}
@font-face {
  font-family: "Inter var";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("/assets/fonts/Inter-italic-var.woff2?v=3.19") format("woff2");
  font-named-instance: "Italic";
}
.vex-style-dark {
  --background-app-bar: #11151e;
  --footer-background: var(--background-foreground);
  --navigation-background: #1a202e;
  --toolbar-background: #1a202e;
  --background-base: #232b3e;
  --background-foreground: #1a202e;
  --background-foreground-rgb: 26, 32, 46;
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: white;
  --toolbar-color: white;
  --text-color-light: rgba(0, 0, 0, 0.87);
  --foreground-divider: rgba(255, 255, 255, 0.12);
  --text-hint: rgba(255, 255, 255, 0.5);
  --text-hint-light: rgba(0, 0, 0, 0.38);
  --background-hover: rgba(255, 255, 255, 0.04);
  --text-secondary: rgba(255, 255, 255, 0.7);
  --text-secondary-light: rgba(0, 0, 0, 0.54);
  --secondary-toolbar-background: var(--background-foreground);
}
.vex-style-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), .vex-style-dark .mat-mdc-option:focus.mdc-list-item, .vex-style-dark .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .vex-style-dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.vex-style-dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}
.vex-style-dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.vex-style-dark .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.vex-style-dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-style-dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-primary-rgb);
}
.vex-style-dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-style-dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-primary-rgb);
}
.vex-style-dark .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-style-dark .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #232b3e;
}
.vex-style-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-style-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-accent-rgb);
}
.vex-style-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-style-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-accent-rgb);
}
.vex-style-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-style-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #232b3e;
}
.vex-style-dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-style-dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-accent-rgb);
}
.vex-style-dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-style-dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-accent-rgb);
}
.vex-style-dark .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-style-dark .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #232b3e;
}
.vex-style-dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-style-dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-warn-rgb);
}
.vex-style-dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-style-dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-warn-rgb);
}
.vex-style-dark .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.vex-style-dark .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #232b3e;
}
.vex-style-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.vex-style-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.vex-style-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.vex-style-dark .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.vex-style-dark .mat-app-background, .vex-style-dark.mat-app-background {
  background-color: #232b3e;
  color: white;
}
.vex-style-dark .mat-elevation-z0, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z1, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z2, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z3, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z4, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z5, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z6, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z7, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z8, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z9, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z10, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z11, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z12, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z13, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z14, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z15, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z16, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z17, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z18, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z19, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z20, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z21, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z22, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z23, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-elevation-z24, .vex-style-dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.vex-style-dark .mat-mdc-card {
  --mdc-elevated-card-container-color: #1a202e;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1a202e;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.vex-style-dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--color-primary-rgb);
  --mdc-linear-progress-track-color: var(--color-primary-rgb);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-style-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: var(--color-primary-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-primary-rgb));
}
@media (forced-colors: active) {
  .vex-style-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-style-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--color-primary-rgb)'/%3E%3C/svg%3E");
  }
}
.vex-style-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--color-primary-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-primary-rgb));
}
.vex-style-dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--color-accent-rgb);
  --mdc-linear-progress-track-color: var(--color-accent-rgb);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-style-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: var(--color-accent-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-accent-rgb));
}
@media (forced-colors: active) {
  .vex-style-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-style-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--color-accent-rgb)'/%3E%3C/svg%3E");
  }
}
.vex-style-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: var(--color-accent-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-accent-rgb));
}
.vex-style-dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: var(--color-warn-rgb);
  --mdc-linear-progress-track-color: var(--color-warn-rgb);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.vex-style-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: var(--color-warn-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-warn-rgb));
}
@media (forced-colors: active) {
  .vex-style-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .vex-style-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--color-warn-rgb)'/%3E%3C/svg%3E");
  }
}
.vex-style-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: var(--color-warn-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.vex-style-dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.vex-style-dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.vex-style-dark .mdc-text-field--filled .mdc-text-field__ripple::before,
.vex-style-dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.vex-style-dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .vex-style-dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.vex-style-dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .vex-style-dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.vex-style-dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #232936;
}
.vex-style-dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.vex-style-dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.vex-style-dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.vex-style-dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
[dir=rtl] .vex-style-dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .vex-style-dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .vex-style-dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .vex-style-dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.vex-style-dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
.vex-style-dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .vex-style-dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .vex-style-dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.vex-style-dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--color-primary-rgb);
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.vex-style-dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.vex-style-dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.vex-style-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.vex-style-dark .mdc-text-field--disabled .mdc-text-field-character-counter,
.vex-style-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.vex-style-dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.vex-style-dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.vex-style-dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.vex-style-dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.vex-style-dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.vex-style-dark .mdc-text-field--disabled .mdc-notched-outline__leading,
.vex-style-dark .mdc-text-field--disabled .mdc-notched-outline__notch,
.vex-style-dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  .vex-style-dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .vex-style-dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  .vex-style-dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  .vex-style-dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.vex-style-dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #1f2432;
}
[dir=rtl] .vex-style-dark .mdc-text-field--end-aligned .mdc-text-field__input, .vex-style-dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .vex-style-dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .vex-style-dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.vex-style-dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.vex-style-dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.vex-style-dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.vex-style-dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.vex-style-dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.vex-style-dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.vex-style-dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(var(--color-primary-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(var(--color-accent-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(var(--color-accent-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.vex-style-dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.vex-style-dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.vex-style-dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.vex-style-dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #1a202e);
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.vex-style-dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.vex-style-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__start,
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__content,
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-style-dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.vex-style-dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.vex-style-dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.vex-style-dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.vex-style-dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.vex-style-dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.vex-style-dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(var(--color-primary-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(var(--color-accent-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.vex-style-dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.vex-style-dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #1a202e);
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.vex-style-dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.vex-style-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__start,
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__content,
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-style-dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.vex-style-dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.vex-style-dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #1a202e;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.vex-style-dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #353b47;
  --mdc-chip-elevated-disabled-container-color: #353b47;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.vex-style-dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .vex-style-dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: var(--color-primary-rgb);
  --mdc-chip-elevated-disabled-container-color: var(--color-primary-rgb);
  --mdc-chip-label-text-color: var(--color-primary-contrast-rgb);
  --mdc-chip-disabled-label-text-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-icon-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-icon-disabled-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-icon-selected-icon-color: var(--color-primary-contrast-rgb);
}
.vex-style-dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .vex-style-dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: var(--color-accent-rgb);
  --mdc-chip-elevated-disabled-container-color: var(--color-accent-rgb);
  --mdc-chip-label-text-color: var(--color-accent-contrast-rgb);
  --mdc-chip-disabled-label-text-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-icon-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-icon-disabled-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-icon-selected-icon-color: var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .vex-style-dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: var(--color-warn-rgb);
  --mdc-chip-elevated-disabled-container-color: var(--color-warn-rgb);
  --mdc-chip-label-text-color: var(--color-warn-contrast-rgb);
  --mdc-chip-disabled-label-text-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-icon-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-icon-disabled-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-icon-selected-icon-color: var(--color-warn-contrast-rgb);
}
.vex-style-dark .mat-mdc-chip-focus-overlay {
  background: white;
}
.vex-style-dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.vex-style-dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-primary-rgb);
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: var(--color-primary-rgb);
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-accent-rgb);
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: var(--color-accent-rgb);
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-warn-rgb);
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: var(--color-warn-rgb);
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.vex-style-dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: var(--color-primary-rgb);
  --mdc-slider-focus-handle-color: var(--color-primary-rgb);
  --mdc-slider-hover-handle-color: var(--color-primary-rgb);
  --mdc-slider-active-track-color: var(--color-primary-rgb);
  --mdc-slider-inactive-track-color: var(--color-primary-rgb);
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-primary-rgb);
  --mat-mdc-slider-ripple-color: var(--color-primary-rgb);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--color-primary-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--color-primary-rgb), 0.2);
}
.vex-style-dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: var(--color-accent-rgb);
  --mdc-slider-focus-handle-color: var(--color-accent-rgb);
  --mdc-slider-hover-handle-color: var(--color-accent-rgb);
  --mdc-slider-active-track-color: var(--color-accent-rgb);
  --mdc-slider-inactive-track-color: var(--color-accent-rgb);
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-accent-rgb);
  --mat-mdc-slider-ripple-color: var(--color-accent-rgb);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--color-accent-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--color-accent-rgb), 0.2);
}
.vex-style-dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: var(--color-warn-rgb);
  --mdc-slider-focus-handle-color: var(--color-warn-rgb);
  --mdc-slider-hover-handle-color: var(--color-warn-rgb);
  --mdc-slider-active-track-color: var(--color-warn-rgb);
  --mdc-slider-inactive-track-color: var(--color-warn-rgb);
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-warn-rgb);
  --mat-mdc-slider-ripple-color: var(--color-warn-rgb);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--color-warn-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--color-warn-rgb), 0.2);
}
.vex-style-dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #1a202e);
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.vex-style-dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.vex-style-dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__start,
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__content,
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.vex-style-dark .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-style-dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.vex-style-dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mdc-list-divider::after {
  border-bottom-color: white;
}
.vex-style-dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.vex-style-dark .mat-mdc-menu-item[disabled],
.vex-style-dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.vex-style-dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.vex-style-dark .mat-mdc-menu-item .mat-icon-no-color,
.vex-style-dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mat-mdc-menu-item:hover:not([disabled]),
.vex-style-dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.vex-style-dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.vex-style-dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.vex-style-dark .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.vex-style-dark .mdc-list-item__start,
.vex-style-dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-primary-rgb);
}
.vex-style-dark .mat-accent .mdc-list-item__start,
.vex-style-dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-accent-rgb);
}
.vex-style-dark .mat-warn .mdc-list-item__start,
.vex-style-dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-warn-rgb);
}
.vex-style-dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-style-dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-style-dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-style-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.vex-style-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--color-primary-rgb);
}
.vex-style-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.vex-style-dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--color-primary-rgb);
}
.vex-style-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.vex-style-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.vex-style-dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.vex-style-dark .mat-mdc-paginator {
  background: #1a202e;
  color: rgba(255, 255, 255, 0.87);
}
.vex-style-dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
.vex-style-dark .mat-mdc-paginator-decrement,
.vex-style-dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
.vex-style-dark .mat-mdc-paginator-first,
.vex-style-dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
.vex-style-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.vex-style-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.vex-style-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.vex-style-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-mdc-tab-group, .vex-style-dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--color-primary-rgb);
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-primary-rgb);
  --mat-tab-header-active-ripple-color: var(--color-primary-rgb);
  --mat-tab-header-inactive-ripple-color: var(--color-primary-rgb);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-primary-rgb);
  --mat-tab-header-active-hover-label-text-color: var(--color-primary-rgb);
  --mat-tab-header-active-focus-indicator-color: var(--color-primary-rgb);
  --mat-tab-header-active-hover-indicator-color: var(--color-primary-rgb);
}
.vex-style-dark .mat-mdc-tab-group.mat-accent, .vex-style-dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: var(--color-accent-rgb);
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-accent-rgb);
  --mat-tab-header-active-ripple-color: var(--color-accent-rgb);
  --mat-tab-header-inactive-ripple-color: var(--color-accent-rgb);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-accent-rgb);
  --mat-tab-header-active-hover-label-text-color: var(--color-accent-rgb);
  --mat-tab-header-active-focus-indicator-color: var(--color-accent-rgb);
  --mat-tab-header-active-hover-indicator-color: var(--color-accent-rgb);
}
.vex-style-dark .mat-mdc-tab-group.mat-warn, .vex-style-dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: var(--color-warn-rgb);
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-warn-rgb);
  --mat-tab-header-active-ripple-color: var(--color-warn-rgb);
  --mat-tab-header-inactive-ripple-color: var(--color-warn-rgb);
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-warn-rgb);
  --mat-tab-header-active-hover-label-text-color: var(--color-warn-rgb);
  --mat-tab-header-active-focus-indicator-color: var(--color-warn-rgb);
  --mat-tab-header-active-hover-indicator-color: var(--color-warn-rgb);
}
.vex-style-dark .mat-mdc-tab-group.mat-background-primary, .vex-style-dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--color-primary-rgb);
  --mat-tab-header-with-background-foreground-color: var(--color-primary-contrast-rgb);
}
.vex-style-dark .mat-mdc-tab-group.mat-background-accent, .vex-style-dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: var(--color-accent-rgb);
  --mat-tab-header-with-background-foreground-color: var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-mdc-tab-group.mat-background-warn, .vex-style-dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: var(--color-warn-rgb);
  --mat-tab-header-with-background-foreground-color: var(--color-warn-contrast-rgb);
}
.vex-style-dark .mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-style-dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-style-dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.vex-style-dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.vex-style-dark .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--color-primary-rgb);
}
.vex-style-dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--color-accent-rgb);
}
.vex-style-dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: var(--color-warn-rgb);
}
.vex-style-dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1a202e;
  --mdc-filled-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--color-primary-rgb);
  --mdc-filled-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--color-accent-rgb);
  --mdc-filled-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: var(--color-warn-rgb);
  --mdc-filled-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1a202e;
  --mdc-protected-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--color-primary-rgb);
  --mdc-protected-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--color-accent-rgb);
  --mdc-protected-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: var(--color-warn-rgb);
  --mdc-protected-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.vex-style-dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.vex-style-dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--color-primary-rgb);
}
.vex-style-dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--color-accent-rgb);
}
.vex-style-dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: var(--color-warn-rgb);
}
.vex-style-dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-mdc-button, .vex-style-dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-style-dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-button.mat-primary, .vex-style-dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: var(--color-primary-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-primary-rgb), 0.1);
}
.vex-style-dark .mat-mdc-button.mat-accent, .vex-style-dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: var(--color-accent-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-accent-rgb), 0.1);
}
.vex-style-dark .mat-mdc-button.mat-warn, .vex-style-dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: var(--color-warn-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-warn-rgb), 0.1);
}
.vex-style-dark .mat-mdc-raised-button, .vex-style-dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-style-dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-raised-button.mat-primary, .vex-style-dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-raised-button.mat-accent, .vex-style-dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-raised-button.mat-warn, .vex-style-dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-style-dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.vex-style-dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.vex-style-dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.vex-style-dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--color-primary-rgb);
  --mat-mdc-button-persistent-ripple-color: var(--color-primary-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-primary-rgb), 0.1);
}
.vex-style-dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--color-accent-rgb);
  --mat-mdc-button-persistent-ripple-color: var(--color-accent-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-accent-rgb), 0.1);
}
.vex-style-dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: var(--color-warn-rgb);
  --mat-mdc-button-persistent-ripple-color: var(--color-warn-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-warn-rgb), 0.1);
}
.vex-style-dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-fab, .vex-style-dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.vex-style-dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .vex-style-dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.vex-style-dark .mat-mdc-fab.mat-primary, .vex-style-dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-fab.mat-accent, .vex-style-dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-fab.mat-warn, .vex-style-dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.vex-style-dark .mat-mdc-fab.mat-unthemed, .vex-style-dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #1a202e;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.vex-style-dark .mat-mdc-fab.mat-primary, .vex-style-dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: var(--color-primary-rgb);
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.vex-style-dark .mat-mdc-fab.mat-accent, .vex-style-dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: var(--color-accent-rgb);
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.vex-style-dark .mat-mdc-fab.mat-warn, .vex-style-dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: var(--color-warn-rgb);
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.vex-style-dark .mat-mdc-fab[disabled][disabled], .vex-style-dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d1d2d5;
  --mdc-snackbar-supporting-text-color: rgba(26, 32, 46, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.vex-style-dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #1a202e);
  border-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mdc-data-table__row {
  background-color: inherit;
}
.vex-style-dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #1a202e);
}
.vex-style-dark .mdc-data-table__row--selected {
  background-color: rgba(var(--color-primary-rgb), 0.04);
}
.vex-style-dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.vex-style-dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.vex-style-dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mdc-data-table__cell,
.vex-style-dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.vex-style-dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.vex-style-dark .mdc-data-table__pagination-total,
.vex-style-dark .mdc-data-table__pagination-rows-per-page-label,
.vex-style-dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .vex-style-dark .mdc-data-table__pagination-button .mdc-button__icon, .vex-style-dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.vex-style-dark .mat-mdc-table {
  background: #1a202e;
}
.vex-style-dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--color-primary-rgb);
}
.vex-style-dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--color-accent-rgb);
}
.vex-style-dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--color-warn-rgb);
}
.vex-style-dark .mat-badge {
  position: relative;
}
.vex-style-dark .mat-badge.mat-badge {
  overflow: visible;
}
.vex-style-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.vex-style-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.vex-style-dark .ng-animate-disabled .mat-badge-content,
.vex-style-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.vex-style-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.vex-style-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.vex-style-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.vex-style-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.vex-style-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.vex-style-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .vex-style-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.vex-style-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.vex-style-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.vex-style-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.vex-style-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.vex-style-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .vex-style-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.vex-style-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.vex-style-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.vex-style-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.vex-style-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.vex-style-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .vex-style-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.vex-style-dark .mat-badge-content {
  color: var(--color-primary-contrast-rgb);
  background: var(--color-primary-rgb);
}
.cdk-high-contrast-active .vex-style-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.vex-style-dark .mat-badge-accent .mat-badge-content {
  background: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-badge-warn .mat-badge-content {
  color: var(--color-warn-contrast-rgb);
  background: var(--color-warn-rgb);
}
.vex-style-dark .mat-badge-disabled .mat-badge-content {
  background: #656b78;
  color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1a202e;
  color: white;
}
.vex-style-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.vex-style-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.vex-style-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.vex-style-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1a202e;
}
.vex-style-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.vex-style-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #353b47;
}
.vex-style-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #353b47;
}
.vex-style-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #353b47;
}
.vex-style-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.vex-style-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.vex-style-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.vex-style-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1a202e;
}
.vex-style-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.vex-style-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.vex-style-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #353b47;
}
.vex-style-dark .mat-calendar-arrow {
  fill: white;
}
.vex-style-dark .mat-datepicker-toggle,
.vex-style-dark .mat-datepicker-content .mat-calendar-next-button,
.vex-style-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.vex-style-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-calendar-table-header,
.vex-style-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.vex-style-dark .mat-calendar-body-cell-content,
.vex-style-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.vex-style-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.vex-style-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.vex-style-dark .mat-calendar-body-in-range::before {
  background: var(--color-primary-rgb);
}
.vex-style-dark .mat-calendar-body-comparison-identical,
.vex-style-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.vex-style-dark .mat-calendar-body-comparison-bridge-start::before,
.vex-style-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--color-primary-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.vex-style-dark .mat-calendar-body-comparison-bridge-end::before,
.vex-style-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--color-primary-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.vex-style-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.vex-style-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.vex-style-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.vex-style-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.vex-style-dark .mat-calendar-body-selected {
  background-color: var(--color-primary-rgb);
  color: var(--color-primary-contrast-rgb);
}
.vex-style-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
.vex-style-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--color-primary-contrast-rgb);
}
.vex-style-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.vex-style-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color-primary-rgb);
}
@media (hover: hover) {
  .vex-style-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--color-primary-rgb);
  }
}
.vex-style-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1a202e;
  color: white;
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: var(--color-accent-rgb);
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.vex-style-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--color-accent-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.vex-style-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--color-accent-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
.vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.vex-style-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color-accent-rgb);
}
@media (hover: hover) {
  .vex-style-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--color-accent-rgb);
  }
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: var(--color-warn-rgb);
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.vex-style-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--color-warn-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.vex-style-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--color-warn-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: var(--color-warn-rgb);
  color: var(--color-warn-contrast-rgb);
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
.vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--color-warn-contrast-rgb);
}
.vex-style-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.vex-style-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color-warn-rgb);
}
@media (hover: hover) {
  .vex-style-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--color-warn-rgb);
  }
}
.vex-style-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-datepicker-toggle-active {
  color: var(--color-primary-rgb);
}
.vex-style-dark .mat-datepicker-toggle-active.mat-accent {
  color: var(--color-accent-rgb);
}
.vex-style-dark .mat-datepicker-toggle-active.mat-warn {
  color: var(--color-warn-rgb);
}
.vex-style-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.vex-style-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-expansion-panel {
  background: #1a202e;
  color: white;
}
.vex-style-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .vex-style-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .vex-style-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .vex-style-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1a202e;
  }
}
.vex-style-dark .mat-expansion-panel-header-title {
  color: white;
}
.vex-style-dark .mat-expansion-panel-header-description,
.vex-style-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.vex-style-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.vex-style-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.vex-style-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.vex-style-dark .mat-icon.mat-primary {
  color: var(--color-primary-rgb);
}
.vex-style-dark .mat-icon.mat-accent {
  color: var(--color-accent-rgb);
}
.vex-style-dark .mat-icon.mat-warn {
  color: var(--color-warn-rgb);
}
.vex-style-dark .mat-drawer-container {
  background-color: #232b3e;
  color: white;
}
.vex-style-dark .mat-drawer {
  background-color: #1a202e;
  color: white;
}
.vex-style-dark .mat-drawer.mat-drawer-push {
  background-color: #1a202e;
}
.vex-style-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.vex-style-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.vex-style-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.vex-style-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(229, 223, 209, 0.6);
}
.vex-style-dark .mat-step-header.cdk-keyboard-focused, .vex-style-dark .mat-step-header.cdk-program-focused, .vex-style-dark .mat-step-header:hover:not([aria-disabled]), .vex-style-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.vex-style-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .vex-style-dark .mat-step-header:hover {
    background: none;
  }
}
.vex-style-dark .mat-step-header .mat-step-label,
.vex-style-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.vex-style-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--color-primary-contrast-rgb);
}
.vex-style-dark .mat-step-header .mat-step-icon-selected,
.vex-style-dark .mat-step-header .mat-step-icon-state-done,
.vex-style-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: var(--color-primary-rgb);
  color: var(--color-primary-contrast-rgb);
}
.vex-style-dark .mat-step-header.mat-accent .mat-step-icon {
  color: var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.vex-style-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.vex-style-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-step-header.mat-warn .mat-step-icon {
  color: var(--color-warn-contrast-rgb);
}
.vex-style-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.vex-style-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.vex-style-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: var(--color-warn-rgb);
  color: var(--color-warn-contrast-rgb);
}
.vex-style-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: var(--color-warn-rgb);
}
.vex-style-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.vex-style-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: var(--color-warn-rgb);
}
.vex-style-dark .mat-stepper-horizontal, .vex-style-dark .mat-stepper-vertical {
  background-color: #1a202e;
}
.vex-style-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-horizontal-stepper-header::before,
.vex-style-dark .mat-horizontal-stepper-header::after,
.vex-style-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.vex-style-dark .mat-sort-header-arrow {
  color: #babcc0;
}
.vex-style-dark .mat-toolbar {
  background: #11151e;
  color: white;
}
.vex-style-dark .mat-toolbar.mat-primary {
  background: var(--color-primary-rgb);
  color: var(--color-primary-contrast-rgb);
}
.vex-style-dark .mat-toolbar.mat-accent {
  background: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}
.vex-style-dark .mat-toolbar.mat-warn {
  background: var(--color-warn-rgb);
  color: var(--color-warn-contrast-rgb);
}
.vex-style-dark .mat-toolbar .mat-form-field-underline,
.vex-style-dark .mat-toolbar .mat-form-field-ripple,
.vex-style-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.vex-style-dark .mat-toolbar .mat-form-field-label,
.vex-style-dark .mat-toolbar .mat-focused .mat-form-field-label,
.vex-style-dark .mat-toolbar .mat-select-value,
.vex-style-dark .mat-toolbar .mat-select-arrow,
.vex-style-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.vex-style-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.vex-style-dark .mat-tree {
  background: #1a202e;
}
.vex-style-dark .mat-tree-node,
.vex-style-dark .mat-nested-tree-node {
  color: white;
}

.vex-style-light {
  --navigation-background: var(--background-foreground);
  --sidenav-background: white;
  --toolbar-background: var(--background-foreground);
  --sidenav-color: var(--text-color);
  --sidenav-item-background-hover: #f2f2f2;
  --sidenav-item-background-active: #f7f7f7;
  --sidenav-item-color: var(--text-color);
  --sidenav-item-color-hover: var(--text-color);
  --sidenav-item-color-active: var(--text-color);
  --sidenav-item-dropdown-background: #f7f7f7;
  --sidenav-item-dropdown-background-hover: #ebebeb;
  --sidenav-item-dropdown-color-hover: var(--text-color);
  --sidenav-item-dropdown-background-active: #e6e6e6;
  --sidenav-item-dropdown-color-active: var(--text-color);
  --sidenav-item-icon-color-active: rgb(var(--color-primary));
  --sidenav-toolbar-background: white;
  --sidenav-toolbar-background-rgb: 255, 255, 255;
  --sidenav-section-divider-color: rgba(82, 63, 105, 0.06);
}
.vex-style-light .sidenav {
  @apply border-r border-r-gray-200;
}
.vex-style-light .sidenav .vex-sidenav-user .vex-sidenav-user__subtitle,
.vex-style-light .sidenav .vex-sidenav-user .vex-sidenav-user__dropdown-icon {
  @apply text-contrast-black/50;
}
.vex-style-light .sidenav .vex-sidenav-user:hover {
  @apply bg-gray-100 text-gray-900;
}
.vex-style-light .sidenav .vex-sidenav-search {
  @apply bg-gray-100 text-gray-600;
}
.vex-style-light .sidenav .vex-sidenav-search:hover {
  @apply bg-gray-200 text-gray-900;
}
.vex-style-light .vex-sidenav-user--open {
  @apply bg-gray-100;
}

:root {
  --padding-gutter: 1rem;
  --padding: 1.5rem;
  --padding-16: 1rem;
  --padding-12: 0.75rem;
  --padding-8: 0.5rem;
  --padding-6: 0.375rem;
  --padding-4: 0.25rem;
  --color-primary: 99, 102, 241;
  --color-primary-rgb: rgb(99, 102, 241);
  --color-primary-contrast: 255, 255, 255;
  --color-primary-contrast-rgb: rgb(255, 255, 255);
  --color-accent: 255, 64, 129;
  --color-accent-rgb: rgb(255, 64, 129);
  --color-accent-contrast: 255, 255, 255;
  --color-accent-contrast-rgb: rgb(255, 255, 255);
  --color-warn: 244, 67, 54;
  --color-warn-rgb: rgb(244, 67, 54);
  --color-warn-contrast: 255, 255, 255;
  --color-warn-contrast-rgb: rgb(255, 255, 255);
  --font: theme("fontFamily.sans");
  --font-weight-medium: 500;
  --trans-ease-in-out: all var(--trans-ease-in-out-duration) var(--trans-ease-in-out-timing-function);
  --trans-ease-in-out-duration: 500ms;
  --trans-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  --trans-ease-out: all var(--trans-ease-out-duration) var(--trans-ease-out-timing-function);
  --trans-ease-out-duration: 400ms;
  --trans-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  --trans-ease-in: all var(--trans-ease-in-duration) var(--trans-ease-in-timing-function);
  --trans-ease-in-duration: 300ms;
  --trans-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  --trans-shadow-duration: 280ms;
  --trans-shadow-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --trans-shadow: box-shadow var(--trans-shadow-duration) var(--trans-shadow-timing-function);
  --text-color: rgba(0, 0, 0, 0.87);
  --text-color-light: white;
  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-secondary-light: rgba(255, 255, 255, 0.7);
  --text-hint: rgba(0, 0, 0, 0.38);
  --text-hint-light: rgba(255, 255, 255, 0.5);
  --foreground-divider: rgba(82, 63, 105, 0.06);
  --background-base: rgb(245, 245, 248);
  --background-foreground: white;
  --background-foreground-rgb: 255, 255, 255;
  --background-app-bar: #ebebee;
  --background-hover: rgba(0, 0, 0, 0.04);
  --elevation-default: var(--elevation-z6);
  --elevation-z0: none;
  --elevation-z1: 0px 2px 1px -1px rgba(82, 63, 104, 0.06), 0px 1px 1px 0px rgba(82, 63, 104, 0.042), 0px 1px 3px 0px rgba(82, 63, 104, 0.036);
  --elevation-z2: 0px 3px 1px -2px rgba(82, 63, 104, 0.06), 0px 2px 2px 0px rgba(82, 63, 104, 0.042), 0px 1px 5px 0px rgba(82, 63, 104, 0.036);
  --elevation-z3: 0px 3px 3px -2px rgba(82, 63, 104, 0.06), 0px 3px 4px 0px rgba(82, 63, 104, 0.042), 0px 1px 8px 0px rgba(82, 63, 104, 0.036);
  --elevation-z4: 0px 2px 4px -1px rgba(82, 63, 104, 0.06), 0px 4px 5px 0px rgba(82, 63, 104, 0.042), 0px 1px 10px 0px rgba(82, 63, 104, 0.036);
  --elevation-z5: 0px 3px 5px -1px rgba(82, 63, 104, 0.06), 0px 5px 8px 0px rgba(82, 63, 104, 0.042), 0px 1px 14px 0px rgba(82, 63, 104, 0.036);
  --elevation-z6: 0px 3px 5px -1px rgba(82, 63, 104, 0.06), 0px 6px 10px 0px rgba(82, 63, 104, 0.042), 0px 1px 18px 0px rgba(82, 63, 104, 0.036);
  --elevation-z7: 0px 4px 5px -2px rgba(82, 63, 104, 0.06), 0px 7px 10px 1px rgba(82, 63, 104, 0.042), 0px 2px 16px 1px rgba(82, 63, 104, 0.036);
  --elevation-z8: 0px 5px 5px -3px rgba(82, 63, 104, 0.06), 0px 8px 10px 1px rgba(82, 63, 104, 0.042), 0px 3px 14px 2px rgba(82, 63, 104, 0.036);
  --elevation-z9: 0px 5px 6px -3px rgba(82, 63, 104, 0.06), 0px 9px 12px 1px rgba(82, 63, 104, 0.042), 0px 3px 16px 2px rgba(82, 63, 104, 0.036);
  --elevation-z10: 0px 6px 6px -3px rgba(82, 63, 104, 0.06), 0px 10px 14px 1px rgba(82, 63, 104, 0.042), 0px 4px 18px 3px rgba(82, 63, 104, 0.036);
  --elevation-z11: 0px 6px 7px -4px rgba(82, 63, 104, 0.06), 0px 11px 15px 1px rgba(82, 63, 104, 0.042), 0px 4px 20px 3px rgba(82, 63, 104, 0.036);
  --elevation-z12: 0px 7px 8px -4px rgba(82, 63, 104, 0.06), 0px 12px 17px 2px rgba(82, 63, 104, 0.042), 0px 5px 22px 4px rgba(82, 63, 104, 0.036);
  --elevation-z13: 0px 7px 8px -4px rgba(82, 63, 104, 0.06), 0px 13px 19px 2px rgba(82, 63, 104, 0.042), 0px 5px 24px 4px rgba(82, 63, 104, 0.036);
  --elevation-z14: 0px 7px 9px -4px rgba(82, 63, 104, 0.06), 0px 14px 21px 2px rgba(82, 63, 104, 0.042), 0px 5px 26px 4px rgba(82, 63, 104, 0.036);
  --elevation-z15: 0px 8px 9px -5px rgba(82, 63, 104, 0.06), 0px 15px 22px 2px rgba(82, 63, 104, 0.042), 0px 6px 28px 5px rgba(82, 63, 104, 0.036);
  --elevation-z16: 0px 8px 10px -5px rgba(82, 63, 104, 0.06), 0px 16px 24px 2px rgba(82, 63, 104, 0.042), 0px 6px 30px 5px rgba(82, 63, 104, 0.036);
  --elevation-z17: 0px 8px 11px -5px rgba(82, 63, 104, 0.06), 0px 17px 26px 2px rgba(82, 63, 104, 0.042), 0px 6px 32px 5px rgba(82, 63, 104, 0.036);
  --elevation-z18: 0px 9px 11px -5px rgba(82, 63, 104, 0.06), 0px 18px 28px 2px rgba(82, 63, 104, 0.042), 0px 7px 34px 6px rgba(82, 63, 104, 0.036);
  --elevation-z19: 0px 9px 12px -6px rgba(82, 63, 104, 0.06), 0px 19px 29px 2px rgba(82, 63, 104, 0.042), 0px 7px 36px 6px rgba(82, 63, 104, 0.036);
  --elevation-z20: 0px 10px 13px -6px rgba(82, 63, 104, 0.06), 0px 20px 31px 3px rgba(82, 63, 104, 0.042), 0px 8px 38px 7px rgba(82, 63, 104, 0.036);
  --sidenav-width: 280px;
  --sidenav-collapsed-width: 72px;
  --sidenav-background: #1a202e;
  --sidenav-color: white;
  --sidenav-toolbar-background: #171d29;
  --sidenav-toolbar-background-rgb: 23, 29, 41;
  --sidenav-section-divider-color: rgba(255, 255, 255, 0.12);
  --sidenav-item-min-height: 48px;
  --sidenav-item-padding-vertical: var(--padding-8);
  --sidenav-item-padding-horizontal: var(--padding);
  --sidenav-item-background-hover: #141924;
  --sidenav-item-background-active: #141924;
  --sidenav-item-font-weight: 500;
  --sidenav-item-color: #A1A2B6;
  --sidenav-item-color-hover: white;
  --sidenav-item-color-active: white;
  --sidenav-item-icon-color: #494B74;
  --sidenav-item-icon-color-hover: rgb(var(--color-primary));
  --sidenav-item-icon-color-active: rgb(var(--color-primary));
  --sidenav-item-icon-gap: 16px;
  --sidenav-item-icon-size: 24px;
  --sidenav-item-dropdown-background: #141924;
  --sidenav-item-dropdown-background-hover: #131721;
  --sidenav-item-dropdown-color-hover: var(--sidenav-item-color-hover);
  --sidenav-item-dropdown-background-active: var(--sidenav-item-dropdown-background-hover);
  --sidenav-item-dropdown-color-active: var(--sidenav-item-color-active);
  --sidenav-item-ripple-color: rgba(0, 0, 0, 0.1);
  --sidenav-item-dropdown-gap: 12px;
  --sidenav-subheading-color: #6b6e7f;
  --toolbar-height: 64px;
  --toolbar-background: white;
  --toolbar-color: rgba(0, 0, 0, 0.87);
  --toolbar-icon-color: rgb(var(--color-primary));
  --secondary-toolbar-background: var(--background-foreground);
  --secondary-toolbar-height: 54px;
  --navigation-height: 64px;
  --navigation-background: var(--background-foreground);
  --navigation-color: var(--text-secondary);
  --footer-height: 56px;
  --footer-z-index: 100;
  --footer-background: var(--background-foreground);
  --footer-color: var(--text-color);
  --footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, .06);
  --page-layout-header-height: 200px;
  --page-layout-toolbar-height: 64px;
  --blink-scrollbar-width: 12px;
  --default-icon-size: 24px;
  --border-radius: 0.25rem;
}
@screen lg {
  :root {
    --padding-gutter: 1.5rem;
  }
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-primary-rgb);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-primary-rgb);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-accent-rgb);
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-accent-rgb);
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-accent-rgb);
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-accent-rgb);
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--color-warn-rgb);
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--color-warn-rgb);
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: black;
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.009em);
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card {
  --mat-card-title-text-font: var(--font);
  --mat-card-title-text-line-height: 26px;
  --mat-card-title-text-size: 18px;
  --mat-card-title-text-tracking: -0.014em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: var(--font);
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: -0.006em;
  --mat-card-subtitle-text-weight: 500;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--color-primary-rgb);
  --mdc-linear-progress-track-color: var(--color-primary-rgb);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: var(--color-primary-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-primary-rgb));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--color-primary-rgb)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--color-primary-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-primary-rgb));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--color-accent-rgb);
  --mdc-linear-progress-track-color: var(--color-accent-rgb);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: var(--color-accent-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-accent-rgb));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--color-accent-rgb)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: var(--color-accent-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-accent-rgb));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: var(--color-warn-rgb);
  --mdc-linear-progress-track-color: var(--color-warn-rgb);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: var(--color-warn-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-warn-rgb));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='var(--color-warn-rgb)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: var(--color-warn-rgb);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, var(--color-warn-rgb));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: var(--font);
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--color-primary-rgb);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(var(--color-primary-rgb), 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(var(--color-accent-rgb), 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(var(--color-accent-rgb), 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, var(--color-accent-rgb));
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, var(--color-warn-rgb));
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.011em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.011em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.011em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.009em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(15px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 15px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(var(--color-primary-rgb), 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(var(--color-accent-rgb), 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(var(--color-warn-rgb), 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.011em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.009em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.011em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: var(--font);
  --mdc-dialog-subhead-line-height: 26px;
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: -0.014em;
  --mdc-dialog-supporting-text-font: var(--font);
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: -0.009em;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: var(--color-primary-rgb);
  --mdc-chip-elevated-disabled-container-color: var(--color-primary-rgb);
  --mdc-chip-label-text-color: var(--color-primary-contrast-rgb);
  --mdc-chip-disabled-label-text-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-icon-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-icon-disabled-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--color-primary-contrast-rgb);
  --mdc-chip-with-icon-selected-icon-color: var(--color-primary-contrast-rgb);
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: var(--color-accent-rgb);
  --mdc-chip-elevated-disabled-container-color: var(--color-accent-rgb);
  --mdc-chip-label-text-color: var(--color-accent-contrast-rgb);
  --mdc-chip-disabled-label-text-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-icon-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-icon-disabled-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--color-accent-contrast-rgb);
  --mdc-chip-with-icon-selected-icon-color: var(--color-accent-contrast-rgb);
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: var(--color-warn-rgb);
  --mdc-chip-elevated-disabled-container-color: var(--color-warn-rgb);
  --mdc-chip-label-text-color: var(--color-warn-contrast-rgb);
  --mdc-chip-disabled-label-text-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-icon-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-icon-disabled-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--color-warn-contrast-rgb);
  --mdc-chip-with-icon-selected-icon-color: var(--color-warn-contrast-rgb);
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-primary-rgb);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--color-primary-rgb);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-accent-rgb);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--color-accent-rgb);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-warn-rgb);
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: var(--color-warn-rgb);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: var(--color-primary-rgb);
  --mdc-slider-focus-handle-color: var(--color-primary-rgb);
  --mdc-slider-hover-handle-color: var(--color-primary-rgb);
  --mdc-slider-active-track-color: var(--color-primary-rgb);
  --mdc-slider-inactive-track-color: var(--color-primary-rgb);
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-primary-rgb);
  --mat-mdc-slider-ripple-color: var(--color-primary-rgb);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--color-primary-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--color-primary-rgb), 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: var(--color-accent-rgb);
  --mdc-slider-focus-handle-color: var(--color-accent-rgb);
  --mdc-slider-hover-handle-color: var(--color-accent-rgb);
  --mdc-slider-active-track-color: var(--color-accent-rgb);
  --mdc-slider-inactive-track-color: var(--color-accent-rgb);
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-accent-rgb);
  --mat-mdc-slider-ripple-color: var(--color-accent-rgb);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--color-accent-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--color-accent-rgb), 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: var(--color-warn-rgb);
  --mdc-slider-focus-handle-color: var(--color-warn-rgb);
  --mdc-slider-hover-handle-color: var(--color-warn-rgb);
  --mdc-slider-active-track-color: var(--color-warn-rgb);
  --mdc-slider-inactive-track-color: var(--color-warn-rgb);
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-warn-rgb);
  --mat-mdc-slider-ripple-color: var(--color-warn-rgb);
  --mat-mdc-slider-hover-ripple-color: rgba(var(--color-warn-rgb), 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(var(--color-warn-rgb), 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: var(--font);
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: -0.006em;
  --mdc-slider-label-label-text-weight: 500;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, var(--color-primary-rgb));
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.011em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.009em);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: black;
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: black;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: black;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-icon-color: var(--color-primary-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-primary-rgb);
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-icon-color: var(--color-accent-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-accent-rgb);
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-icon-color: var(--color-warn-rgb);
  --mdc-radio-selected-pressed-icon-color: var(--color-warn-rgb);
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--color-primary-rgb);
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--color-primary-rgb);
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: var(--font);
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: -0.009em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: var(--font);
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: -0.006em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: var(--font);
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: var(--font);
  letter-spacing: -0.011em;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--color-primary-rgb);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-primary-rgb);
  --mat-tab-header-active-ripple-color: var(--color-primary-rgb);
  --mat-tab-header-inactive-ripple-color: var(--color-primary-rgb);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-primary-rgb);
  --mat-tab-header-active-hover-label-text-color: var(--color-primary-rgb);
  --mat-tab-header-active-focus-indicator-color: var(--color-primary-rgb);
  --mat-tab-header-active-hover-indicator-color: var(--color-primary-rgb);
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: var(--color-accent-rgb);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-accent-rgb);
  --mat-tab-header-active-ripple-color: var(--color-accent-rgb);
  --mat-tab-header-inactive-ripple-color: var(--color-accent-rgb);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-accent-rgb);
  --mat-tab-header-active-hover-label-text-color: var(--color-accent-rgb);
  --mat-tab-header-active-focus-indicator-color: var(--color-accent-rgb);
  --mat-tab-header-active-hover-indicator-color: var(--color-accent-rgb);
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: var(--color-warn-rgb);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-warn-rgb);
  --mat-tab-header-active-ripple-color: var(--color-warn-rgb);
  --mat-tab-header-inactive-ripple-color: var(--color-warn-rgb);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-warn-rgb);
  --mat-tab-header-active-hover-label-text-color: var(--color-warn-rgb);
  --mat-tab-header-active-focus-indicator-color: var(--color-warn-rgb);
  --mat-tab-header-active-hover-indicator-color: var(--color-warn-rgb);
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--color-primary-rgb);
  --mat-tab-header-with-background-foreground-color: var(--color-primary-contrast-rgb);
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: var(--color-accent-rgb);
  --mat-tab-header-with-background-foreground-color: var(--color-accent-contrast-rgb);
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: var(--color-warn-rgb);
  --mat-tab-header-with-background-foreground-color: var(--color-warn-contrast-rgb);
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: var(--font);
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-letter-spacing: -0.006em;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-accent-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-primary-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-warn-rgb);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--color-primary-rgb);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--color-accent-rgb);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: var(--color-warn-rgb);
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--color-primary-rgb);
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--color-accent-rgb);
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: var(--color-warn-rgb);
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--color-primary-rgb);
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--color-accent-rgb);
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: var(--color-warn-rgb);
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--color-primary-rgb);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--color-accent-rgb);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: var(--color-warn-rgb);
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: var(--color-primary-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-primary-rgb), 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: var(--color-accent-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-accent-rgb), 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: var(--color-warn-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-warn-rgb), 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--color-primary-rgb);
  --mat-mdc-button-persistent-ripple-color: var(--color-primary-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-primary-rgb), 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--color-accent-rgb);
  --mat-mdc-button-persistent-ripple-color: var(--color-accent-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-accent-rgb), 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: var(--color-warn-rgb);
  --mat-mdc-button-persistent-ripple-color: var(--color-warn-rgb);
  --mat-mdc-button-ripple-color: rgba(var(--color-warn-rgb), 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: var(--color-primary-rgb);
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: var(--color-accent-rgb);
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: var(--color-warn-rgb);
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: var(--color-accent-rgb);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: var(--font);
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(var(--color-primary-rgb), 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, var(--font)));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, -0.006em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--color-primary-rgb);
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--color-accent-rgb);
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--color-warn-rgb);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: var(--color-primary-contrast-rgb);
  background: var(--color-primary-rgb);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}

.mat-badge-warn .mat-badge-content {
  color: var(--color-warn-contrast-rgb);
  background: var(--color-warn-rgb);
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: var(--font);
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: black;
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--font);
  letter-spacing: -0.006em;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: black;
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #f5f3f6;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #f5f3f6;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #f5f3f6;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: black;
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #f5f3f6;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: var(--font);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(82, 63, 105, 0.06);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: black;
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(82, 63, 105, 0.12);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: var(--color-primary-rgb);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--color-primary-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--color-primary-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: var(--color-primary-rgb);
  color: var(--color-primary-contrast-rgb);
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--color-primary-contrast-rgb);
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color-primary-rgb);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--color-primary-rgb);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: var(--color-accent-rgb);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--color-accent-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--color-accent-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--color-accent-contrast-rgb);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color-accent-rgb);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--color-accent-rgb);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: var(--color-warn-rgb);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--color-warn-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--color-warn-rgb) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: var(--color-warn-rgb);
  color: var(--color-warn-contrast-rgb);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--color-warn-contrast-rgb);
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color-warn-rgb);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--color-warn-rgb);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: var(--color-primary-rgb);
}
.mat-datepicker-toggle-active.mat-accent {
  color: var(--color-accent-rgb);
}
.mat-datepicker-toggle-active.mat-warn {
  color: var(--color-warn-rgb);
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: var(--font);
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(82, 63, 105, 0.06);
}

.mat-divider-vertical {
  border-right-color: rgba(82, 63, 105, 0.06);
}

.mat-expansion-panel {
  background: white;
  color: black;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(82, 63, 105, 0.06);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: black;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: var(--font);
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--font);
  letter-spacing: -0.006em;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: var(--color-primary-rgb);
}
.mat-icon.mat-accent {
  color: var(--color-accent-rgb);
}
.mat-icon.mat-warn {
  color: var(--color-warn-rgb);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: black;
}

.mat-drawer {
  background-color: white;
  color: black;
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(82, 63, 105, 0.06);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(82, 63, 105, 0.06);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(82, 63, 105, 0.06);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(82, 63, 105, 0.06);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: var(--color-primary-contrast-rgb);
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--color-primary-rgb);
  color: var(--color-primary-contrast-rgb);
}
.mat-step-header.mat-accent .mat-step-icon {
  color: var(--color-accent-contrast-rgb);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: var(--color-warn-contrast-rgb);
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: var(--color-warn-rgb);
  color: var(--color-warn-contrast-rgb);
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: var(--color-warn-rgb);
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: black;
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: var(--color-warn-rgb);
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(82, 63, 105, 0.06);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(82, 63, 105, 0.06);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: var(--font);
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 15px;
}

.mat-step-label-selected {
  font-size: 15px;
  font-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: #ebebee;
  color: black;
}
.mat-toolbar.mat-primary {
  background: var(--color-primary-rgb);
  color: var(--color-primary-contrast-rgb);
}
.mat-toolbar.mat-accent {
  background: var(--color-accent-rgb);
  color: var(--color-accent-contrast-rgb);
}
.mat-toolbar.mat-warn {
  background: var(--color-warn-rgb);
  color: var(--color-warn-contrast-rgb);
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--font);
  letter-spacing: -0.014em;
  margin: 0;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: black;
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: var(--font);
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.horizontal-layout {
  --navigation-height: 0.00001px;
  --toolbar-width: 100%;
}
@screen lg {
  .horizontal-layout {
    --toolbar-width: calc(100% - var(--sidenav-width));
  }
  .horizontal-layout.sidenav-collapsed {
    --toolbar-width: calc(100% - var(--sidenav-collapsed-width));
  }
}
.horizontal-layout .sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}
.horizontal-layout.has-fixed-footer.scroll-disabled .content {
  height: calc(100% - var(--toolbar-height) - var(--footer-height));
}

@screen lg {
  body:not([dir=rtl]) .horizontal-layout.sidenav-collapsed .sidenav-content {
    margin-left: var(--sidenav-collapsed-width) !important;
    margin-right: 0 !important;
  }
  body:not([dir=rtl]) .horizontal-layout:not(.sidenav-collapsed) .sidenav-content {
    margin-left: var(--sidenav-width) !important;
    margin-right: 0 !important;
  }
  [dir=rtl] .horizontal-layout.sidenav-collapsed .sidenav-content {
    margin-right: var(--sidenav-collapsed-width) !important;
    margin-left: 0 !important;
  }
  [dir=rtl] .horizontal-layout:not(.sidenav-collapsed) .sidenav-content {
    margin-right: var(--sidenav-width) !important;
    margin-left: 0 !important;
  }
}
/** SCROLLBARS */
body.is-blink ::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  height: var(--blink-scrollbar-width);
  width: var(--blink-scrollbar-width);
}
body.is-blink ::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
body.is-blink ::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: var(--blink-scrollbar-width);
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
}
body.is-blink ::-webkit-scrollbar-thumb:active {
  border-radius: var(--blink-scrollbar-width);
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
}

/** SCROLLBLOCK */
.vex-scrollblock {
  position: fixed;
  width: 100%;
}

/** GLOSSY DIALOG */
.vex-dialog-glossy .mat-mdc-dialog-container {
  @apply rounded;
}

.is-blink .vex-dialog-glossy .mat-mdc-dialog-container .mdc-dialog__surface {
  backdrop-filter: saturate(180%) blur(20px);
  @apply bg-foreground/60;
}

.vertical-layout {
  --toolbar-width: 100%;
}
.vertical-layout vex-secondary-toolbar .h-14 {
  display: none;
}
.vertical-layout vex-secondary-toolbar .fixed {
  background: none;
  border-top: none;
  box-shadow: none;
  margin-bottom: calc(var(--padding-16) * -1);
  padding-top: var(--padding-12);
  position: relative;
  top: 0;
}
.vertical-layout.content-container > .sidenav-container > .sidenav-content > .content {
  margin-left: auto;
  margin-right: auto;
}
.vertical-layout.has-fixed-footer.scroll-disabled .content {
  height: calc(100% - var(--toolbar-height) - var(--footer-height));
}

@media print {
  html, body {
    height: auto !important;
    overflow: initial !important;
  }
  .vex-toolbar, .sidenav {
    display: none !important;
  }
  .content {
    margin-top: 0 !important;
  }
  .mat-drawer-container {
    overflow: visible !important;
  }
  .mat-drawer-side {
    border-right: none !important;
  }
  .sidenav-content {
    margin-left: 0 !important;
    @apply bg-foreground;
  }
  .vex-footer {
    display: none !important;
  }
  .config-panel-toggle {
    display: none !important;
  }
}
/*
  InterDynamicTracking takes the font size in points
  or pixels and returns the compensating tracking in EM.
  function InterDynamicTracking(fontSize) {
    const a = -0.0223
    const b = 0.185
    const c = -0.1745;
    // tracking = a + b * e ^ (c * fontSize)
    return a + b * Math.pow(Math.E, c * fontSize)
  }
*/
.display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: var(--font);
  letter-spacing: -0.05em;
}

.display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: var(--font);
  letter-spacing: -0.02em;
}

.display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: var(--font);
  letter-spacing: -0.005em;
}

.display-1, h1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: var(--font);
  letter-spacing: normal;
}

.headline, h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: var(--font);
  letter-spacing: -0.019em;
}

.title, h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--font);
  letter-spacing: -0.014em;
}

.subheading-2, h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: var(--font);
  letter-spacing: -0.011em;
}

.subheading-1, h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--font);
  letter-spacing: -0.006em;
}

.body-2, h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--font);
  letter-spacing: -0.006em;
}

.body-1, p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: var(--font);
  letter-spacing: -0.009em;
}

.caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--font);
  letter-spacing: 0;
}

.text-xs {
  letter-spacing: 0;
}

.text-sm {
  letter-spacing: -0.009em;
}

.text-base {
  letter-spacing: -0.011em;
}

.text-lg {
  letter-spacing: -0.014em;
}

.text-2xl {
  letter-spacing: -0.019em;
}

.text-3xl {
  letter-spacing: -0.021em;
}

:root .mat-mdc-raised-button:not(:disabled):not(.mat-unthemed), :root .mat-mdc-unelevated-button:not(:disabled):not(.mat-unthemed), :root .mat-mdc-fab:not(:disabled):not(.mat-unthemed), :root .mat-mdc-mini-fab:not(:disabled):not(.mat-unthemed) {
  @apply text-primary-contrast;
}
:root .mat-mdc-button, :root .mat-mdc-outlined-button, :root .mat-mdc-unelevated-button, :root .mat-mdc-raised-button {
  @apply rounded-button min-w-[6rem];
}
:root .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
  @apply rounded-button;
}
:root .mat-button-toggle-group:not(.mat-button-toggle-vertical) .mat-button-toggle:first-child .mat-button-toggle-label-content {
  @apply pl-4;
}
:root .mat-button-toggle-group:not(.mat-button-toggle-vertical) .mat-button-toggle:last-child .mat-button-toggle-label-content {
  @apply pr-4;
}
:root .mat-button-toggle-button {
  @apply font-medium;
}
:root .mat-mdc-icon-button .mat-icon {
  @apply inline-flex items-center justify-center;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: theme("textColor.primary-contrast");
}

:root {
  /** Reset default height placeholder */
}
:root .mat-mdc-dialog-container .mdc-dialog__surface {
  @apply rounded-lg;
}
:root .mat-mdc-dialog-title {
  @apply pt-3;
}
:root .mdc-dialog__title::before {
  content: none;
  height: auto;
}

.mdc-notched-outline__notch {
  border-right: none;
}

:root .mat-mdc-menu-panel {
  @apply rounded shadow;
}
:root .mat-mdc-menu-content {
  @apply p-2;
}
:root .mat-mdc-menu-item {
  @apply flex items-center rounded text-sm font-medium min-h-[40px] pl-2 pr-6 transition duration-100 ease-out;
}
:root .mat-mdc-menu-item:hover:not([disabled]) {
  @apply bg-primary/10;
}
:root .mat-mdc-menu-item:hover:not([disabled]) .mat-icon {
  @apply text-primary;
}
:root .mat-mdc-menu-item .mat-icon {
  @apply transition duration-100 ease-out;
  font-size: 1.25rem;
  height: 1.25rem;
  width: 1.25rem;
}
:root .mat-mdc-menu-item-submenu-trigger {
  @apply pr-12;
}

.mat-mdc-menu-item .mat-icon-no-color {
  color: currentColor;
}

.mat-mdc-icon-button .mat-mdc-button-wrapper > *,
.mat-menu-item .mat-icon,
.mat-button .mat-icon {
  vertical-align: middle !important;
}

.mat-mdc-table .mat-mdc-cell,
.mat-table .mat-mdc-header-cell {
  box-sizing: content-box;
  white-space: nowrap;
}

.mat-mdc-select-panel {
  @apply text-base;
}

.mat-drawer-side {
  border: 0;
}

:root .mat-mdc-select-panel {
  @apply rounded shadow p-2;
}
:root .mat-mdc-option {
  @apply rounded text-sm font-medium h-10 transition duration-100 ease-out;
}
:root .mat-mdc-option:hover, :root .mat-mdc-option.mat-active {
  @apply bg-primary/10;
}
:root .mat-mdc-option:hover .mat-icon, :root .mat-mdc-option.mat-active .mat-icon {
  @apply text-primary;
}
:root .mat-mdc-option .mat-icon {
  @apply transition duration-100 ease-out;
  font-size: 1.25rem;
  height: 1.25rem;
  width: 1.25rem;
}
:root .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
  @apply bg-primary/20;
}
:root .mat-mdc-menu-item-submenu-trigger {
  @apply pr-12;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar, .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar, .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background: #e2e2e2;
}

.vex-style-dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar, .vex-style-dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar, .vex-style-dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background: rgba(255, 255, 255, 0.5);
}

.apexcharts-legend-text {
  margin-left: 4px;
  top: -1px;
}

body .apexcharts-tooltip.light {
  background: var(--background-foreground);
}

.vex-layout-ares {
  --footer-background: var(--background-base);
  --sidenav-background: var(--background-base);
  --toolbar-background: var(--background-base);
  --sidenav-color: var(--text-color);
  --sidenav-section-divider-color: transparent;
  --sidenav-item-background-hover: rgba(var(--color-primary), 0.1);
  --sidenav-item-background-active: var(--background-foreground);
  --sidenav-item-color: var(--text-color);
  --sidenav-item-color-hover: var(--text-color);
  --sidenav-item-color-active: var(--text-color);
  --sidenav-item-dropdown-color-active: var(--sidenav-item-color-active);
  --sidenav-item-dropdown-background-active: var(--background-hover);
  --sidenav-item-dropdown-background: var(--background-base);
  --sidenav-item-dropdown-background-hover: var(--background-hover);
  --secondary-toolbar-background: var(--background-base);
  --sidenav-toolbar-background: var(--background-base);
  --secondary-toolbar-height: 64px;
}
.vex-layout-ares .sidenav {
  border: none;
}
.vex-layout-ares .sidenav vex-sidenav-item .vex-sidenav-item {
  width: unset;
  @apply rounded-full py-2 mx-4;
}
.vex-layout-ares .sidenav vex-sidenav-item .vex-sidenav-item.vex-sidenav-item--active {
  @apply shadow;
}
.vex-layout-ares .sidenav vex-sidenav-item .item-level-0 > .vex-sidenav-item {
  @apply my-1;
}
.vex-layout-ares .sidenav vex-sidenav-item .item-level-1 .vex-sidenav-item.vex-sidenav-item--active {
  @apply shadow-none font-medium;
}
.vex-layout-ares .sidenav vex-sidenav-item .item-level-1 .vex-sidenav-item.vex-sidenav-item--active .vex-sidenav-item__label {
  @apply text-black;
}
.vex-layout-ares .sidenav vex-sidenav-item .item-level-1 .vex-sidenav-item:hover .vex-sidenav-item__label {
  @apply text-black;
}
.vex-layout-ares .sidenav-collapsed .sidenav vex-sidenav-item .vex-sidenav-item {
  @apply mx-0;
}
.vex-layout-ares vex-secondary-toolbar {
  @apply rounded-b overflow-hidden;
}
.vex-layout-ares vex-secondary-toolbar .secondary-toolbar {
  @apply shadow-none border-t-0 -mb-gutter;
}
.vex-layout-ares vex-footer {
  @apply rounded-t overflow-hidden;
}
.vex-layout-ares .vex-page-layout-header {
  background: var(--background-base);
  color: var(--text-color);
}
.vex-layout-ares .vex-page-layout-header vex-breadcrumbs .text-hint {
  color: var(--text-hint) !important;
}
.vex-layout-ares.vex-style-default {
  --sidenav-item-background-active: theme("backgroundColor.primary");
  --sidenav-item-color-active: theme("textColor.primary-contrast");
  --sidenav-item-dropdown-color-active: var(--sidenav-item-color);
  --sidenav-item-dropdown-color-hover: var(--text-color);
  --sidenav-item-icon-color-active: theme("textColor.primary-contrast");
}

.vex-layout-hermes {
  --toolbar-height: 100px;
  --secondary-toolbar-background: var(--background-base);
  --secondary-toolbar-height: 64px;
}
.vex-layout-hermes.vex-style-light {
  --toolbar-background: var(--background-base);
}
.vex-layout-hermes.vex-style-default {
  --navigation-background: #1a202e;
  --toolbar-background: #232b3e;
  --navigation-color: white;
  --toolbar-color: white;
}
.vex-layout-hermes.vex-style-dark {
  --toolbar-background: var(--background-base);
}
@screen lg {
  .vex-layout-hermes .sidenav {
    @apply hidden;
  }
}
.vex-layout-hermes vex-secondary-toolbar .secondary-toolbar {
  @apply shadow-none -mb-gutter border-t-0;
}

.vex-layout-ikaros {
  --navigation-height: 0.00001px;
}
.vex-layout-ikaros.vex-style-default {
  --toolbar-background: #1a202e;
  --navigation-color: white;
  --toolbar-color: white;
}

.vex-layout-poseidon {
  --sidenav-item-min-height: 40px;
  --sidenav-item-background-hover: rgba(var(--color-primary), 0.1);
  --sidenav-item-background-active: rgba(var(--color-primary), 0.2);
  --sidenav-item-dropdown-background: var(--sidenav-background);
  --sidenav-item-dropdown-background-hover: rgba(var(--color-primary), 0.1);
  --sidenav-item-dropdown-background-active: rgba(var(--color-primary), 0.2);
  --sidenav-item-padding-vertical: var(--padding-8);
  --sidenav-item-padding-horizontal: var(--padding-12);
}
.vex-layout-poseidon .vex-sidenav-item {
  @apply mx-3 mb-1 rounded;
}
.vex-layout-poseidon .vex-sidenav-toolbar__divider {
  margin: 0 var(--sidenav-item-padding-horizontal);
}
.vex-layout-poseidon.vex-style-light {
  --sidenav-item-color: rgba(0, 0, 0, 1);
  --sidenav-item-color-hover: rgba(0, 0, 0, 1);
  --sidenav-item-dropdown-color-hover: rgba(0, 0, 0, 1);
  --sidenav-item-dropdown-color-active: rgba(0, 0, 0, 1);
}
.vex-layout-poseidon.vex-style-default {
  --sidenav-background: #0a1929;
  --sidenav-toolbar-background: var(--sidenav-background);
  --sidenav-item-color: rgba(255, 255, 255, 0.8);
  --sidenav-item-color-hover: rgba(255, 255, 255, 1);
  --sidenav-item-ripple-color: rgb(255, 255, 255, 0.06);
}
.vex-layout-poseidon.vex-style-dark {
  --sidenav-toolbar-background: theme("backgroundColor.foreground");
  --sidenav-background: theme("backgroundColor.foreground");
  --sidenav-item-color: rgba(255, 255, 255, 0.8);
  --sidenav-item-color-hover: #FFFFFF;
  --sidenav-item-ripple-color: rgb(255, 255, 255, 0.06);
}

.header-height {
  height: 60px !important;
}

.timepicker__body .clock-face {
  background-color: rgba(0, 0, 0, 0.6588235294) !important;
}

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/
/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */